import React from 'react';

export default function NotFound() {
    return (
        <main>
            <h1>Page Not Found</h1>
            <p>Oops, we couldn't find this page!</p>
        </main>
    );
}
